import * as _ from 'lodash';
import { convertCoordinatesToGeocoding } from '~/utils/map.util';

export const formatAirVisibilityBookmark = (bookmark: any) => {
  return bookmark;
};

interface IAirDateTime {
  actual: string
  estimated: string | null
  local?: string
}

interface IAirLocation {
  country: string
  country_code: string
  iata_code: string
  icao_code: string
  lat: number
  lng: number
  name: string
  state: string
}

interface IAirEvent {
  datetime: IAirDateTime
  description: string
  event_code: string
  flight_number: string | null
  location: IAirLocation
  piece: number
  sequence_id: string
  weight: number
}

interface IAirGroupedEvent {
  location: IAirLocation
  events: IAirEvent[]
}

export const groupAirEventsByLocation = (events: IAirEvent[] = []): IAirGroupedEvent[] => {
  if (!Array.isArray(events)) {
    return [];
  }

  const grouped: { [key: string]: IAirGroupedEvent } = {};

  events.forEach((event: IAirEvent) => {
    try {
      const locationKey = `${event.location?.country}-${event.location?.iata_code}`;
      if (!grouped[locationKey]) {
        grouped[locationKey] = {
          location: event.location,
          events: [],
        };
      }
      grouped[locationKey].events.push(event);
    }
    catch (e) {
      console.error(e);
    }
  });

  return Object.values(grouped);
};

export const getAwbInfoStatusColor = (data: any) => {
  try {
    const status = data?.awb_info?.status?.toUpperCase();
    const grayStatuses = ['BOOKED', 'RECEIVED', 'DEPARTED', 'ARRIVED'];
    if (grayStatuses.includes(status)) {
      return 'gray';
    }
    if (status === 'DELIVERED') {
      return 'blue';
    }
    if (status === 'CANCELLED') {
      return 'red';
    }
  }
  catch (e) {
    return 'gray';
  }
};

export const getAirline: any = (bookmark: any) => _.get(bookmark, 'awb_info.airline');
export const getFightList: any = (bookmark: any) => {
  const routes = _.get(bookmark, 'routes', []).map((route: any) => {
    return route.flight_number;
  });
  return routes.length ? routes.join(', ') : '';
};

export const getFlightPath = (bookmark: any) => {
  const mapDataLayers: any = [];
  const routes = _.get(bookmark, 'routes', []);
  if (!routes.length) {
    return '';
  }
  try {
    routes.forEach(({ estimated_flight_path }: any) => {
      const coordinates = estimated_flight_path.map((entry: any) => [entry[1], entry[0]]);
      mapDataLayers.push({
        id: Math.random().toString(36).substring(7),
        type: 'line',
        source: {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: convertCoordinatesToGeocoding(coordinates),
            },
          },
        },
        paint: { 'line-color': '#afafaf', 'line-width': 2, 'line-dasharray': [2, 1.5] },
      });
    });
  }
  catch (e) {
    console.log(e);
  }
  return mapDataLayers;
};
export const getTransitAirportList = (bookmark: any) => {
  try {
    const routes = _.get(bookmark, 'routes', []);
    const transitAirports: any = {};
    routes.forEach((route: any) => {
      if (route.to) {
        transitAirports[route.to.iata_code] = route.to;
      }
    });
    const origin = _.get(bookmark, 'awb_info.from.iata_code');
    const designation = _.get(bookmark, 'awb_info.to.iata_code');
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete transitAirports[origin];
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete transitAirports[designation];

    return Object.values(transitAirports);
  }
  catch (e) {
    console.log(e);
    return [];
  }
};

export const getAirVisMapMiddlePoint = (bookmark: any) => {
  const from = _.get(bookmark, 'awb_info.from');
  const to = _.get(bookmark, 'awb_info.to');
  if (!from || !to) {
    return null;
  }
  if (from.lat && from.lng && to.lat && to.lng) {
    const coordinates = [[from.lat, from.lng], [to.lat, to.lng]];
    const convertedCoordinates: any = convertCoordinatesToGeocoding(coordinates);
    return middlePoint({
      lat: convertedCoordinates[0][0],
      lon: convertedCoordinates[0][1],
    }, {
      lat: convertedCoordinates[1][0],
      lon: convertedCoordinates[1][1],
    });
  }
};
